<template>
  <div>
    <b-container fluid class="p-5">
      <b-link @click="goLast">返回公告列表</b-link>
      <b-row>
        <b-col :md="7" class="border-right">
          <b-card class="shadow m-3 p-3" style="min-height: 75vh">
            <NoticePage :notice-id="getParamsId"></NoticePage>
          </b-card>
        </b-col>
        <b-col :md="5">
          <b-card class="shadow p-3 m-3 mx-0">
            <h6>发布评论</h6>
            <b-row>
              <b-form-textarea
                  class="w-75"
                  style="height: 80px"
                  id="textarea"
                  v-model="comment"
                  placeholder="从此处开始输入..."
                  rows="6"
                  max-rows="12"
              ></b-form-textarea>
              <b-button class="w-25" variant="primary" @click="makeComment">
                <i class="el-icon-s-promotion" style="font-size: 30px"></i>
              </b-button>
            </b-row>
          </b-card>
          <b-card style="max-height: 500px;overflow: scroll" class="shadow p-3 m-3 mx-0">
            <h6>评论</h6>
            <b-media v-if="commentFlag" v-for="c in comments" :key="c.id">
              <b-row>
                <b-col :md="2">
                  <b-avatar src="https://placekitten.com/300/300"></b-avatar>
                </b-col>
                <b-col :md="10"><h5 class="mt-0">{{ c.commentatorId }}</h5>
                  <p>
                    {{ c.content }}
                  </p></b-col>
              </b-row>
              <el-divider></el-divider>
            </b-media>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NoticePage from "@/components/Notice/components/NoticePage";
import {makeComment, showNoticeCommentsByNoticeId} from "@/api";

export default {
  name: "NoticeDetails",
  components: {NoticePage},
  computed: {
    getParamsId() {
      return Number(this.$route.params.noticeId)
    }
  },
  data() {
    return {
      commentFlag: true,
      noticeId: 0,
      comment: '',
      comments: []
    }
  },
  mounted() {
    this.noticeId = this.$route.params.noticeId
    this.getComments()
  },
  methods: {
    goLast(){
      this.$router.go(-1)
    },
    reloadComponent() {
      this.commentFlag = false
      this.$nextTick(() => {
        this.commentFlag = true
      })
    },
    makeComment() {
      makeComment(this.noticeId, this.comment).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.getComments()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getComments() {
      showNoticeCommentsByNoticeId(this.noticeId, 1, 20).then(res => {
        if (res.data.status) {
          this.comments = res.data.data.content
          this.comments.reverse()
          this.reloadComponent()
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
